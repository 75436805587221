import React, { useEffect, useState } from "react";
import "./EmailModal.scss";
import axios from "axios";
import { emailInfo, workspaceInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { ClipLoader } from "react-spinners";
import w from "app/shared/AgTable/AgTableClient/w";


const EmailModal = ({ isOpen, onClose, email, vendorName, invoiceNumber, invoiceDate, gstin, onSend ,vendorData}) => {
  console.log("vendorData",vendorData)
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);

  const [fromEmail, setFromEmail] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [toEmails, setToEmails] = useState([]);

  const [updatedVendorData, setUpdatedVendorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  // useEffect(() => {
  //   if (vendorData && vendorData.length > 0) {
  //     // Extract emails from the vendorData and ensure uniqueness
  //     const emails = [...new Set(vendorData.map(vendor => vendor.email).filter(email => email))];
  //     console.log(emails);
  //     setToEmails(emails); // Set the unique emails as the initial value
  //   }
  // }, [vendorData]); // This effect will run whenever vendorData changes


   // useEffect to fetch email and cc details when the modal is opened
  useEffect(() => {
  console.log("Modal isOpen:", isOpen);
  if (isOpen) {
  
    fetchVendorDetails();
  }
}, [isOpen]);

  // Function to fetch email and CCs for each vendor
  const fetchVendorDetails = async () => {
    try {
      setIsLoading(true);
      const workspace = localStorage.getItem('currentWorkspace');
      console.log(workspace,"workspace in email modal")
      // Remove duplicate vendors based on vendorName
    const uniqueVendorData = Array.from(
      new Map(vendorData.map((vendor) => [vendor.vendorName, vendor])).values()
    );
    console.log("Unique Vendor Data:", uniqueVendorData);
      const updatedData = await Promise.all(
        uniqueVendorData.map(async (vendor) => {
          const { vendorName, gstin } = vendor;

          console.log(gstin,"vendor in email modal")

          // Fetch email from the backend
          const emailResponse = await axios.post("https://fuzzyapi.finkraftai.com/api/get-email", {
            gstin,
            workspace,
          });

          // Fetch CCs from the backend
          const ccResponse = await axios.post("https://fuzzyapi.finkraftai.com/api/get-ccs", {
            vendorName,
            workspace,
          });

          return {
            ...vendor,
            email: emailResponse.data.email || [],
            ccs: ccResponse.data.ccs || [],
          };
        })
      );
      console.log("updatedData",updatedData)
      setUpdatedVendorData(updatedData);

       // Extract all emails and CCs and remove duplicates
       const allEmails = updatedData.flatMap(vendor => vendor.email);
       const allCCs = updatedData.flatMap(vendor => vendor.ccs);
 
       setToEmails([...new Set(allEmails)]); // Remove duplicates
       setCcEmails([...new Set(allCCs)]); // Remove duplicates
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    } finally {
      setIsLoading(false);
    }
  };
  




  const [isFromStyled, setIsFromStyled] = useState(false);
  const [isToStyled, setIsToStyled] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [isCcStyled, setIsCcStyled] = useState(false);
  const [isBccStyled, setIsBccStyled] = useState(false);
  const [isCcVisible, setIsCcVisible] = useState(true);
  const [isBccVisible, setIsBccVisible] = useState(false);


  const removeDuplicates = (emails) => [...new Set(emails)];

  // useEffect(() => {
  //   const fetchCcEmails = async () => {
  //     try {
  //       // Extract the vendorName from vendorData (assuming you want to send the first vendorName or change this based on logic)
  //       const vendorNameFromData = vendorData[0]?.vendorName; // Example: taking the first vendorName from vendorData
  //       if (!vendorNameFromData) {
  //         console.error("No vendorName found in vendorData");
  //         return;
  //       }
  
  //       // Prepare the payload with vendorName
  //       const payload = {
  //         workspaceid: "33ab7bdd-609b-4551-90b8-9becc33ccb7d", // Replace with your dynamic workspace ID if needed
  //         vendorName: vendorNameFromData, // Use the vendorName from vendorData
  //       };
  
  //       // Send a POST request with the vendorName in the payload
  //       const response = await fetch("https://fuzzyapi.finkraftai.com/get-cc", {
  //         method: "POST", // Using POST to send data in the body
  //         headers: {
  //           "Content-Type": "application/json", // Specify the content type as JSON
  //         },
  //         body: JSON.stringify(payload), // Send the payload as JSON
  //       });
  
  //       const data = await response.json();
  //       console.log("data", data);
  
  //       // Check if the response contains an email field
  //       if (data.email) {
  //         // If there's only one email, directly add it to the ccEmails array
  //         const emailList = [data.email]; // Wrap the email in an array
  //         console.log("emailList", emailList);
  //         setCcEmails(removeDuplicates(emailList)); // Set the CC emails
  //       } else {
  //         console.error("Email not found in response");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching CC emails:", error);
  //     }
  //   };
  
  //   if (vendorData.length > 0) {
  //     fetchCcEmails(); // Fetch CC emails only if vendorData is available
  //   }
  // }, [isOpen, vendorData]);
  

  const [templateId, setTemplateId] = useState("d-744bd0419cf54c3cac21b81b4731e0cb"); // Default template ID

  // Fetch template content based on the selected templateId
  const fetchTemplateContent = async (templateId) => {
    try {
      const response = await fetch(`https://fuzzyapi.finkraftai.com/api/template/${templateId}`);
      const data = await response.json();
      if (data.plain_content) {
        const bodyContent = data.plain_content
          .replace(/\*/g, '') // Removes all stars
          .replace(/Subject:.*(\r?\n)+/, '') // Removes the subject line and following newline(s)
          .trim();



      // Replace placeholders with actual values
      const populatedBody = bodyContent
        .replace('{{vendor_name}}', 'Vendor')  // Replace vendor name
        .replace('{{invoice_number}}', invoiceNumber)  // Replace invoice number
        .replace('{{invoice_date}}', invoiceDate)  // Replace invoice date
        .replace('{{your_name}}', "Your Name")  // Replace with your actual name
        .replace('{{your_position}}', "Your Position")  // Replace with your position
        .replace('{{your_contact_information}}', "Your Contact Info");  

      // Set the dynamic body content
      setBody(populatedBody);

      } else {
        console.error("Failed to load template content");
      }
    } catch (error) {
      console.error("Error fetching template content:", error);
    }
  };

  // useEffect(() => {
  //   if (templateId) {
  //     fetchTemplateContent(templateId);
  //   }
  // }, [templateId]);

 

  




  const handleTemplateChange = (e) => {
    const selectedTemplate = templates.find(template => template.name === e.target.value);
    setTemplateId(selectedTemplate.id);
    setSubject(selectedTemplate.name); 
  };


  const templates = [
    { name: "Mandatory E-Invoicing Compliance Under GST- Vendor Wise", id: "d-744bd0419cf54c3cac21b81b4731e0cb" },
    { name: "Invoice-Wise Follow-Up 3 (Final Warning via GST Portal)", id: "d-28efe496b27c4943848567ace11566a3" },
    { name: "Invoice-Wise Follow-Up 2 (General Reminder)", id: "d-cd77675790a943be9b6a834848ca3522" },
    { name: "Invoice-Wise Follow-Up 1 (Immediate Notification)", id: "d-315b0365c9754a5dbb97449e653f06a1" },
    { name: "Notification to Generate E-Invoice", id: "d-72485f25393d40f9af4d-744bd0419cf54c3cac21b81b4731e0cb37bf2f09975c5" },
    { name: "Follow-Up Reminder to Generate E-Invoice", id: "d-cd5ac40badac430eb34d4f3ac6510908" },
    { name: "Final Warning from GST Portal", id: "d-01123bf8287349de9dbdbfcf0389873d" },
    { name: "E-Invoice Acknowledgement", id: "d-6da054d0dac0491b96987609d8e0093f" }

  ];



  


  useEffect(() => {
    console.log(vendorName);
  
    if (isOpen) {
      setToEmail(email || ""); // Update toEmail when modal is opened
      const emaillocal = localStorage.getItem("email");
      console.log(emaillocal)

     
      // Extract the username (part before the '@')
      const username = emaillocal.split('@')[0];

      // Create the new email with the desired domain
      const updatedEmail = `${username}@finkraft.ai`;
      console.log(updatedEmail)
      setFromEmail(updatedEmail || "")

      setSubject(`Invitation to provide E-invoice for ${vendorName}`); 
      fetchTemplateContent(templateId);
    }
  }, [isOpen, email, vendorName]);

  useEffect(() => {
    console.log(vendorName);
  
    if (isOpen) {
      setToEmail(""); // Update toEmail when modal is opened
      const emaillocal = localStorage.getItem("email");
      console.log(emaillocal)

     
      // Extract the username (part before the '@')
      const username = emaillocal.split('@')[0];

      // Create the new email with the desired domain
      const updatedEmail = `${username}@finkraft.ai`;
      console.log(updatedEmail)
      setFromEmail(updatedEmail || "")
      
  
      // Set the subject dynamically based on the selected template
      const selectedTemplate = templates.find(template => template.id === templateId);
      setSubject(selectedTemplate ? selectedTemplate.name : ""); // Set subject based on template name
  
      // Fetch the initial template content based on the template ID
      const fetchTemplateContent = async () => {
        try {
          const response = await fetch(`https://fuzzyapi.finkraftai.com/api/template/${templateId}`);
          if (response.ok) {
            const data = await response.json();
         
            const bodyContent = data.plain_content
              .replace(/\*/g, '') // Removes all stars
              .replace(/Subject:.*(\r?\n)+/, '') // Removes the subject line and following newline(s)
              .trim();



      // Replace placeholders with actual values
      const populatedBody = bodyContent
        .replace('{{vendor_name}}', 'Vendor')  // Replace vendor name
        .replace('{{invoice_number}}', invoiceNumber)  // Replace invoice number
        .replace('{{invoice_date}}', invoiceDate)  // Replace invoice date
        .replace('{{your_name}}', "Your Name")  // Replace with your actual name
        .replace('{{your_position}}', "Your Position")  // Replace with your position
        .replace('{{your_contact_information}}', "Your Contact Info");  

      // Set the dynamic body content
      setBody(populatedBody);

            
          } else {
            alert("Failed to load template content.");
          }
        } catch (error) {
          console.error(error);
          alert("Error fetching template content.");
        }
      };
  
      fetchTemplateContent(); // Call the function to fetch content
    }
  }, [isOpen, email, vendorName, templateId]);
  
  


// Reset form fields when the modal closes or when opening a new row
useEffect(() => {
  if (!isOpen) {
    setFromEmail("");
    setToEmail("");
    setCcEmails([]); // Update to handle multiple CC emails
    setBccEmails([]); // Update to handle multiple BCC emails
    setSubject("");
    setBody("");
    setAttachments([]);
    setIsCcVisible(false);
    setIsBccVisible(false);
    setIsFromStyled(false);
    setIsToStyled(false);
  }
}, [isOpen, email]); // Reset on modal open/close

// Handle email input dynamically based on type
const handleEmailEnter = (e, type) => {
  if (e.key === "Enter" && e.target.value.trim()) {
    const email = e.target.value.trim();
    switch (type) {
      case "from":
        setFromEmail(email);
        setIsFromStyled(true);
        break;
      case "to":
        setToEmail(email);
        setIsToStyled(true);
        break;
      case "cc":
        setCcEmails((prev) => [...prev, email]); // Add to CC emails array
        break;
      case "bcc":
        setBccEmails((prev) => [...prev, email]); // Add to BCC emails array
        break;
      default:
        break;
    }
    e.target.value = ""; // Clear the input field after adding an email
  }
};



  const [attachments, setAttachments] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setAttachments([...attachments, ...files]); // Add selected files to the state
  };


  const toggleVisibility = (type) => {
    if (type === "cc") {
      setIsCcVisible(!isCcVisible); // Toggle CC visibility
    } else if (type === "bcc") {
      setIsBccVisible(!isBccVisible); // Toggle BCC visibility
    }
  };

  if (!isOpen) return null;




 

  const handleInputClick = (type) => {
    if (type === "from") {
      setIsFromStyled(false);
    } else if (type === "to") {
      setIsToStyled(false);
    }
    else if (type === "cc") {
      setIsCcStyled(false);
    }
    else if (type === "bcc") {
      setIsBccStyled(false);
    }

  };

  const handleClear = () => {
    console.log("delete clicked");

    // Clear all fields
    setFromEmail("");
    setToEmail("");
    setCcEmails([]);
    setBccEmails([]);
    setSubject("");
    setBody("");
    setIsCcVisible(false); // Hide Cc field
    setIsBccVisible(false); // Hide Bcc field
    setIsFromStyled(false); // Show input for "From" again
    setIsToStyled(false); // Show input for "To" again
    setAttachments([])
  };



  const handleSendInvite = async () => {
    let toEmailsArray;
    let ccEmailsArray;
    let bccEmailsArray;
  
    // Process 'toEmails'
    if (Array.isArray(toEmails)) {
      toEmailsArray = toEmails.map(email => email.trim()).filter(email => email !== "");
    } else if (typeof toEmails === "string") {
      toEmailsArray = toEmails.split(",").map(email => email.trim()).filter(email => email !== "");
    } else {
      console.error("Invalid toEmails format");
      return;
    }
  
    
  
    const payload = {
      personalizations: [
        {
          to: toEmailsArray.map(email => ({ email })),
          cc: ccEmails.map(email => ({ email })),
          bcc: bccEmails.map(email => ({ email })),
          dynamic_template_data: {
            vendor_name: vendorName, // Replace with the vendor's name
            gstin: gstin, // Replace with GSTIN
            your_name: fromEmail, // Replace with your name or email
            your_position: "Your Position", // Replace with your position
            your_contact_information: "Your Contact Info", // Replace with your contact information
            subject: "Mandatory E-Invoicing Compliance Under GST - Vendor Wise", // Include subject here
          },
        },

       
      ],
      from: {
        email: fromEmail, // Replace with your email
      },
      updatedVendorData: updatedVendorData,
      subject: "Mandatory E-Invoicing Compliance Under GST - Vendor Wise",
      content: [
        {
          type: "text/html",
          template_id: templateId, // Replace with your template ID
          value: "<!-- Insert your HTML content here -->",
        },
      ],
      categories: "Vendor_Email", // Optional categories for classification
    };
  
    try {
      const response = await fetch("https://fuzzyapi.finkraftai.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        onSend();
        console.log("Mail sent successfully!");
      } else {
        console.error("Failed to send the email.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while sending the email.");
    }
  };

  const handleEmailTagClick = (field) => {
    if (field === "cc") {
      // Switch between styled and input state for CC
      setIsCcStyled(!isCcStyled);
    } else if (field === "bcc") {
      // Switch between styled and input state for BCC
      setIsBccStyled(!isBccStyled);
    }
  };

 
  




  const handleRemoveAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index)); // Remove file by index
  };

  // Handle removing an email from the list
  const handleRemoveEmail = (email) => {
    setToEmails(toEmails.filter((e) => e !== email));
  };


  
  const handleAddCcEmail = (e) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      const newEmail = e.target.value.trim();
      // Add the email only if it's not already in the list
      if (!ccEmails.includes(newEmail)) {
        setCcEmails([...ccEmails, newEmail]);
      }
      e.target.value = ""; // Clear the input field after adding
    }
  };

  const handleRemoveCcEmail = (emailToRemove) => {
    setCcEmails(ccEmails.filter(email => email !== emailToRemove));
  };

  const handleAddBccEmail = (e) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      const newEmail = e.target.value.trim();
      // Add the email only if it's not already in the list
      if (!bccEmails.includes(newEmail)) {
        setBccEmails([...bccEmails, newEmail]);
      }
      e.target.value = ""; // Clear the input field after adding
    }
  };

  const handleRemoveBccEmail = (emailToRemove) => {
    setBccEmails(bccEmails.filter(email => email !== emailToRemove));
  };

  


  return (
    <div className="email-modal-overlay">

      <div className="email-modal">
      {isLoading && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom:0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
          }}>
            <ClipLoader color="#36D7B7" size={50} />
          </div>
        )}
        {/* Close Button */}
        <div className="email-top">
          {/* Title */}
          <h2 className="email-modal-header">New Message</h2>

          {/* Professional Dropdown */}
          <div className="email-top-right">
            <select className="professional-dropdown" onChange={handleTemplateChange}>
              {templates.map(template => (
                <option key={template.id} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>

            {/* Close Button */}
            <button className="email-modal-close" onClick={onClose}>
              ×
            </button>
          </div>
        </div>


        {/* Email Form */}
        <form className="email-form">
          {/* From Field */}
          {/* <div className="email-field">
            <label htmlFor="from">From</label>
            {isFromStyled ? (
              <div className="email-tag" onClick={() => handleInputClick("from")}>
                <span className="email-tag-icon">👤</span>
                {fromEmail}
              </div>
            ) : (
              <input
                type="text"
                value={fromEmail}
                onKeyDown={(e) => handleEmailEnter(e, "from")}
                onChange={(e) => setFromEmail(e.target.value)}
              />
            )}
          </div> */}
          <div className="email-field">
  <label htmlFor="from">From</label>
  <div className="email-tag">
    <span className="email-tag-icon">👤</span>
    {fromEmail}
  </div>
</div>




<div className="email-field">
  <label htmlFor="to">To</label>

  {/* Display emails as tags */}
  <div style={{
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    maxHeight: "100px", // Set maximum height
    overflowY: "auto", // Enable vertical scrolling
    width:'620px'
  }}>
    {toEmails.map((email, index) => (
      <div key={index} style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
        padding: "5px 10px",
        borderRadius: "16px",
        fontSize: "14px",
      }}>
        <span>{email}</span>
        <button
          type="button"
          style={{
            background: "none",
            border: "none",
            marginLeft: "5px",
            cursor: "pointer",
            color: "#ff0000",
            fontWeight: "bold"
          }}
          onClick={() => handleRemoveEmail(email)}
        >
          ✖
        </button>
      </div>
    ))}
    <div className="email-ccext">
              <span onClick={() => toggleVisibility("cc")}>Cc</span>
              <span onClick={() => toggleVisibility("bcc")}>Bcc</span>
        </div>
  </div>
</div>

{isCcVisible && (
  <div className="email-field">
    <label htmlFor="cc">Cc</label>
    <div className="email-tags-container">
      {ccEmails.map((email, index) => (
        <div key={index} className="email-tag">
          <span>{email}</span>
          <button
            type="button"
            onClick={() => handleRemoveCcEmail(email)}
          >
            ✖
          </button>
        </div>
      ))}
      <input
        type="text"
        onKeyDown={handleAddCcEmail}
        placeholder="Add CC email"
      />
    </div>
  </div>
)}

{isBccVisible && (
  <div className="email-field">
    <label htmlFor="bcc">Bcc</label>
    <div className="email-tags-container">
      {bccEmails.map((email, index) => (
        <div key={index} className="email-tag">
          <span>{email}</span>
          <button
            type="button"
            onClick={() => handleRemoveBccEmail(email)}
          >
            ✖
          </button>
        </div>
      ))}
      <input
        type="text"
        onKeyDown={handleAddBccEmail}
        placeholder="Add BCC email"
      />
    </div>
  </div>
)}

          <div className="email-field">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>


          <div className="email-body-container">
            <textarea
              className="email-body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Write your message here..."
            />
          </div>

          <div className="attachments-list">
            {attachments.map((file, index) => (
              <div key={index} className="attachment-item">
                <span className="attachment-name">{file.name}</span>
                <span className="attachment-size">({(file.size / 1024).toFixed(2)} KB)</span>
                <button
                  className="remove-attachment-btn"
                  onClick={() => handleRemoveAttachment(index)}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>


          {/* Footer Buttons */}
          <div className="email-footer">
            <div className="email-footer-left">
              <button type="button" className="email-send-btn" onClick={handleSendInvite}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                  <path d="M8.3334 11.6667L17.5001 2.5M8.3334 11.6667L11.2501 17.5C11.2866 17.5798 11.3453 17.6474 11.4192 17.6948C11.493 17.7422 11.579 17.7674 11.6667 17.7674C11.7545 17.7674 11.8404 17.7422 11.9143 17.6948C11.9881 17.6474 12.0468 17.5798 12.0834 17.5L17.5001 2.5M8.3334 11.6667L2.50006 8.75C2.42027 8.71344 2.35266 8.65474 2.30526 8.58088C2.25786 8.50701 2.23267 8.4211 2.23267 8.33333C2.23267 8.24557 2.25786 8.15965 2.30526 8.08579C2.35266 8.01193 2.42027 7.95323 2.50006 7.91667L17.5001 2.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Send Remainder
              </button>
              <label>
                <input
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M8.33333 7.5V11.6667C8.33333 12.1087 8.50893 12.5326 8.82149 12.8452C9.13405 13.1577 9.55797 13.3333 10 13.3333C10.442 13.3333 10.866 13.1577 11.1785 12.8452C11.4911 12.5326 11.6667 12.1087 11.6667 11.6667V5.83333C11.6667 4.94928 11.3155 4.10143 10.6904 3.47631C10.0652 2.85119 9.21739 2.5 8.33333 2.5C7.44928 2.5 6.60143 2.85119 5.97631 3.47631C5.35119 4.10143 5 4.94928 5 5.83333V12.5C5 13.8261 5.52678 15.0979 6.46447 16.0355C7.40215 16.9732 8.67392 17.5 10 17.5C11.3261 17.5 12.5979 16.9732 13.5355 16.0355C14.4732 15.0979 15 13.8261 15 12.5V4.16667" stroke="#3F4047" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </label>

              {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_177_10330)">
    <path d="M8.3335 10.8335C8.69137 11.3119 9.14796 11.7078 9.67229 11.9943C10.1966 12.2807 10.7764 12.4511 11.3724 12.4937C11.9683 12.5364 12.5665 12.4504 13.1263 12.2416C13.6861 12.0328 14.1944 11.706 14.6168 11.2835L17.1168 8.78347C17.8758 7.99763 18.2958 6.94512 18.2863 5.85263C18.2768 4.76014 17.8386 3.71509 17.0661 2.94256C16.2935 2.17002 15.2485 1.73182 14.156 1.72233C13.0635 1.71283 12.011 2.13281 11.2252 2.8918L9.79183 4.3168" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.6668 9.16653C11.309 8.68809 10.8524 8.29221 10.328 8.00575C9.80371 7.71928 9.22391 7.54893 8.62796 7.50625C8.03201 7.46357 7.43384 7.54956 6.87405 7.75838C6.31425 7.9672 5.8059 8.29397 5.3835 8.71653L2.8835 11.2165C2.12451 12.0024 1.70453 13.0549 1.71402 14.1474C1.72352 15.2399 2.16172 16.2849 2.93426 17.0574C3.70679 17.83 4.75184 18.2682 5.84433 18.2777C6.93681 18.2872 7.98932 17.8672 8.77517 17.1082L10.2002 15.6832" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_177_10330">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.0835 8.3335C7.77385 8.3335 8.3335 7.77385 8.3335 7.0835C8.3335 6.39314 7.77385 5.8335 7.0835 5.8335C6.39314 5.8335 5.8335 6.39314 5.8335 7.0835C5.8335 7.77385 6.39314 8.3335 7.0835 8.3335Z" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.4998 12.5002L13.3332 8.3335L4.1665 17.5002" stroke="#3F4047" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */}

            </div>
            <div type="button" className="deleteicon" onClick={() => handleClear()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M2.5 5H4.16667H17.5" stroke="#141414" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.8334 4.99984V16.6665C15.8334 17.1085 15.6578 17.5325 15.3453 17.845C15.0327 18.1576 14.6088 18.3332 14.1667 18.3332H5.83341C5.39139 18.3332 4.96746 18.1576 4.6549 17.845C4.34234 17.5325 4.16675 17.1085 4.16675 16.6665V4.99984M6.66675 4.99984V3.33317C6.66675 2.89114 6.84234 2.46722 7.1549 2.15466C7.46746 1.8421 7.89139 1.6665 8.33341 1.6665H11.6667C12.1088 1.6665 12.5327 1.8421 12.8453 2.15466C13.1578 2.46722 13.3334 2.89114 13.3334 3.33317V4.99984" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailModal;
