import React, { useEffect, useRef, useState } from "react";
import "./BhilosaDashboard.scss";
import { AgCharts } from "ag-charts-enterprise";
// import 'ag-grid-enterprise';
import BhilosaLogo from "../../../assets/images/BhilosaLogo.png";
import FinkraftLogo from "../../../assets/images/finkraftlogo.png";
import { Button, Modal, Tag } from "antd";
import tickIcon from "../../../assets/images/tick.png";
import phoneIcon from "../../../assets/images/phone.png";
import GstinLogo from "../../../assets/images/GstinLogo.png";
import GSTPortal from "../../../assets/images/GSTPage.png";
import warninging from "../../../assets/images/warning.png";
import pivot from "../../../assets/images/pivot.png";
import logout from "../../../assets/images/logout.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import "./App.css";
import { useRecoilState } from "recoil";
import { gst2ADataState, gst2BDataState, irndata, roleInfo, userInfo, vendorDetailsDisplay, workspaceInfo } from "app/config/States/users";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { EyeOutlined } from "@ant-design/icons";//new
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as ArrowIcon } from '../arrow.svg';





import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import MainContentVendor from "./VendorScore/pages/demo/MainContent/MainContent";
import { BeatLoader } from "react-spinners";
import EmailModal from "./EmailModel/EmailModel";
import color from "theme/color";


const getMonthOptions = () => {
  const months = [];
  const startMonth = new Date(2024, 3); // April 2024 (months are 0-indexed, so 3 = April)
  const currentMonth = new Date(); // Current date

  // Loop from April 2024 to the current month
  let monthDate = startMonth;
  while (monthDate <= currentMonth) {
    const monthLabel = monthDate.toLocaleString('default', { month: 'short' }) + '-' + monthDate.getFullYear();
    const monthValue = `${(monthDate.getMonth() + 1).toString().padStart(2, '0')}${monthDate.getFullYear()}`;
    months.push({ value: monthValue, label: monthLabel });
    monthDate.setMonth(monthDate.getMonth() + 1); // Move to the next month
  }

  return [{ value: "All", label: "All Months" }, ...months];
};

const GSTIntegration = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { state } = useLocation();
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);

  const { GstinStatus, workspace: selectedWorkspaceFromLocation } = state || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [selectedWorkspace, setSelectedWorkspace] = useState(
    workspace || selectedWorkspaceFromLocation || null
  );
  const closeEmailModal = () => setIsEmailModalOpen(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [stateShortForm, setStateShortForm] = useState("");


  const handleRequestClick = () => {
    setIsEmailModalOpen(true);
  };

  const hasGstin = true;

  const [counts, setCounts] = useState({
    exempted: 0,
    eligible: 0,
    notEligible: 0,
  });
  const [percentages, setPercentages] = useState({
    exempted: 0,
    eligible: 0,
    notEligible: 0,
  });

  const chartRef = useRef(null);

  const [totalVendors, setTotalVendors] = useState(0);
  const [missingContactCount, setMissingContactCount] = useState(0);
  const [compliantVendors, setCompliantVendors] = useState(0);
  const [nonCompliantVendors, setNonCompliantVendors] = useState(0);

  const stateShortFormMapping = {
    "01": "JK",  // Jammu & Kashmir
    "02": "HP",  // Himachal Pradesh
    "03": "PB",  // Punjab
    "04": "CH",  // Chandigarh
    "05": "UK",  // Uttarakhand
    "06": "HR",  // Haryana
    "07": "DL",  // Delhi
    "08": "RJ",  // Rajasthan
    "09": "UP",  // Uttar Pradesh
    "10": "BR",  // Bihar
    "11": "SK",  // Sikkim
    "12": "AR",  // Arunachal Pradesh
    "13": "NL",  // Nagaland
    "14": "MN",  // Manipur
    "15": "MZ",  // Mizoram
    "16": "TR",  // Tripura
    "17": "ML",  // Meghalaya
    "18": "AS",  // Assam
    "19": "WB",  // West Bengal
    "20": "JH",  // Jharkhand
    "21": "OD",  // Odisha
    "22": "CG",  // Chhattisgarh
    "23": "MP",  // Madhya Pradesh
    "24": "GJ",  // Gujarat
    "25": "DD",  // Daman & Diu
    "26": "DN",  // Dadra & Nagar Haveli
    "27": "MH",  // Maharashtra
    "28": "AP",  // Andhra Pradesh
    "29": "KA",  // Karnataka
    "30": "GA",  // Goa
    "31": "LD",  // Lakshadweep
    "32": "KL",  // Kerala
    "33": "TN",  // Tamil Nadu
    "34": "PY",  // Puducherry
    "35": "AN",  // Andaman & Nicobar Islands
    "36": "TG",  // Telangana
    "37": "AP",  // Andhra Pradesh (New)
  };





  useEffect(() => {
    let chartInstance;

    if (counts && percentages && counts.exempted !== undefined) {
      const { exempted, eligible, notEligible } = counts;
      const totalVendors = exempted + eligible + notEligible; // Calculate total vendors

      if (chartRef.current) {
        chartInstance = AgCharts.create({
          container: chartRef.current,
          data: [
            {
              category: "Eligible",
              value: eligible,
              label: `${percentages.eligible}%`,
            },
            {
              category: "Not-eligible",
              value: notEligible,
              label: `${percentages.notEligible}%`,
            },
            {
              category: "Exempted",
              value: exempted,
              label: `${percentages.exempted}%`,
            },
          ],
          series: [
            {
              type: "donut",
              angleKey: "value",
              labelKey: "category",
              fills: ["#006D77", "#83C5BE", "#EDF6F9"],
              strokeWidth: 0,
              innerRadiusOffset: -50,
              innerLabels: [
                {
                  text: totalVendors.toString(), // Dynamic total vendors count
                  fontSize: 20,
                  color: "black",
                },
                {
                  text: "Vendors", // Label
                  fontSize: 12,
                  color: "#718096",
                },
              ],
              tooltip: {
                renderer: (params) => {
                  return {
                    content: `${params.datum.category}: ${params.datum.label}`,
                  };
                },
              },
            },
          ],
          legend: {
            enabled: true, // Enable the legend
            position: "right", // Legend is placed on the right
            spacing: 20, // Adjust spacing between legend items
            item: {
              marker: {
                shape: "circle",
                size: 12, // Adjust size of the circle in the legend
                strokeWidth: 0,
              },
              paddingX: 10, // Space between marker and text
              paddingY: 12, // Space between legend items
              label: {
                fontSize: 14,
                color: "#4A5568",
                formatter: ({ datum }) => {
                  if (datum) {
                    // Format legends as: "Category: Count"
                    return `${datum.category}: ${datum.value}`;
                  }
                  return "";
                },
              },
            },
          },
          background: {
            fill: "transparent",
          },
          padding: {
            right: 50, // Adjust padding to accommodate legend
          },
        });
      }
    }

    // Clean up chart instance on unmount or re-render
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [counts, percentages]); // Re-render when counts or percentages change

  const OnboardingView = () => (
    <div className="onboarding-dashboard">
      <div className="dashboard-header">
        <img src={FinkraftLogo} alt="logo" />
        {/* <div className="dashboard-right-header">
          <div
            className="vendorfollowup-dropdown-container"
            style={{ marginTop: "45px" }}
          >
            <div
              className={`vendorfollowup-dropdown-header ${
                isDropdownOpen ? "active" : ""
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div>
          <Button className="header-btn">
            <img
              src={phoneIcon}
              alt=""
              style={{ width: "19px", padding: "0px" }}
            />
            Contact{" "}
          </Button>

          
        </div>
      </div>

      <div className="content-grid">
        {/* First Row */}
        <div className="card integration-section">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            <img
              src={GstinLogo}
              alt="logo"
              style={{ width: "35px", marginTop: "5px" }}
            />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for
            the claimable amount. To proceed, we require you to authenticate
            with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button">
              <span>→</span>
              Invite user who has GST credentials
            </button>
            <button className="add-pan-button">Add PAN / GSTIN </button>
          </div>

          <div className="info-text">ⓘ Invite user who has credentials</div>
        </div>

        <div className="card portal-preview">
          <img src={GSTPortal} alt="GST Portal" />
        </div>

        {/* Second Row */}
        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Summary</h3>

          <p className="content">
            Stay ahead of GST regulations with a secure platform that provides
            real-time insights into your vendor ecosystem with seamless GST
            integration, monitor e-invoice eligibility in single place.
          </p>

          <Button
            type="primary"
            disabled
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#9DCDD4",
              color: "#fff",
              border: "none",
            }}
          >
            Continue →
          </Button>
        </div>

        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Vendor Management</h3>

          <ul className="vendor-list">
            <li>Monitor GST return filing status of all vendors.</li>
            <li>Tag and categorise vendors based on compliance.</li>
            <li>Automated notifications to vendors for compliance lapses.</li>
          </ul>

          <Button
            type="primary"
            disabled
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#9DCDD4",
              color: "#fff",
              border: "none",
            }}
          >
            Continue →
          </Button>
        </div>
      </div>
    </div>
  );

  const navigate = useNavigate();

  const handleNavigation = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/vendorfollowup/vendoreinvoice?workspace=${workspace}`, { state: { selectedMonths } });
  };

  const summarypage = () => {
    // Navigate to the next page with the hardcoded workspace query parameter

    if (userRole === "ADMIN") {
      navigate(`/credential/gst/list`);
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };

  const handleNavigation1 = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/vendorfollowup/vendorlist`);


  };

  const handleGstIntegration = () => {
    // Navigate to the next page with the hardcoded workspace query parameter

    if (userRole === "ADMIN") {
      navigate("/credential/gst/invite/user");
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };


  const handleAddPan = () => {
    // Navigate to the next page and pass 'pan' as state

    if (userRole === "ADMIN") {
      navigate(`/credential/gst/add/pan`, { state: { pan: "" } });
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };


  const opensetting = () => {
    if (userRole === "ADMIN") {
      navigate(`/vendorfollowup/memebersetting`);
    } else {
      setAccessDeniedModalVisible(true); // Show modal if not an admin
    }
  };

  // useEffect(() => {
  //   // Fetch vendor data
  //   axios
  //     .get(
  //       `https://fuzzyapi.finkraftai.com/vendor-data?workspace=${workspace}`
  //     )
  //     .then((response) => {
  //       const vendorList = response.data.map((vendor) => ({
  //         vendorName: vendor.enablement?.legalNm || "",
  //         gstin: vendor.gstin,
  //         eInvoice: vendor.einvoiceStatus,
  //         invoiceStatus: vendor.invoiceStatus, // Assuming invoiceStatus exists
  //       }));

  //       setTotalVendors(vendorList.length); // Set total vendors count

  //       // Calculate compliant and non-compliant vendors
  //       const compliant = vendorList.filter(
  //         (vendor) =>
  //           vendor.invoiceStatus === "Invoice Received" &&
  //           vendor.eInvoice === "Yes"
  //       ).length;
  //       setCompliantVendors(compliant);

  //       const nonCompliant = vendorList.filter(
  //         (vendor) =>
  //           vendor.invoiceStatus === "Invoice Not Received" &&
  //           vendor.eInvoice === "Yes"
  //       ).length;
  //       setNonCompliantVendors(nonCompliant);

  //       // Fetch contact data
  //       axios
  //         .get(
  //           `https://fuzzyapi.finkraftai.com/get-contacts?workspace=${workspace}`
  //         )
  //         .then((contactResponse) => {
  //           const contactData = contactResponse.data;

  //           // Update missing contact count
  //           const missingContacts = vendorList.filter(
  //             (vendor) =>
  //               !contactData.find(
  //                 (contact) =>
  //                   contact.vendorName === vendor.vendorName &&
  //                   contact.gstin === vendor.gstin
  //               )
  //           );
  //           setMissingContactCount(missingContacts.length); // Set missing contacts count
  //         })
  //         .catch((contactError) => {
  //           console.error("Error fetching contact data:", contactError);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching vendor data:", error);
  //     });
  // }, [workspace]);
  const [entities, setEntities] = useState([]);

  // Fetch data in useEffectc
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentWorkspace = localStorage.getItem('currentWorkspace');
        const response = await axios.get(
          `https://fuzzyapi.finkraftai.com/fetch-gstins?workspace_name=${currentWorkspace}`
        );
        if (response.status === 200) {
          // Map the response data into the format required by the UI
          const formattedData = response.data.map((item) => ({
            pan: item.pan,
            tradeName: item.trade_name,
            gstin: item.gstin_count,
            credStatus: "✔ Completed", // Default Cred Status as Active
          }));
          setEntities(formattedData);
        }
      } catch (error) {
        console.error("Error fetching entities:", error);
        setEntities([]);
      }
    };

    if (workspace) {
      fetchData();
    }
  }, [workspace]);








  const [gstAmountData, setGstAmountData] = useState(null);


  const [gst2AData, setGst2AData] = useRecoilState(gst2ADataState); // State for 2A
  const [gst2BData, setGst2BData] = useRecoilState(gst2BDataState); // State for 2B
  const [loading, setLoading] = useState(true);
  const [is2A, setIs2A] = useState(true); // Default to 2A

  // useEffect(() => {
  //   const fetchGstData = async (tableType) => {
  //     console.log("2A",gst2AData)
  //     const apiUrl = "https://fuzzyapi.finkraftai.com/sum-gst-eligible";
  //     setLoading(true);

  //     try {
  //       const response = await fetch(apiUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           workspaceName: workspace,
  //           tableType: tableType, // Send table type based on toggle (2A or 2B)
  //         }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();

  //         if (tableType === "2A") {
  //           setGst2AData(data); // Store data for 2A
  //         } else {
  //           setGst2BData(data); // Store data for 2B
  //         }
  //       } else {
  //         console.error("Failed to fetch GST data");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   // If workspace is available and data is not already present for 2A, fetch 2A data

  //   if (workspace && !gst2AData) {
  //     fetchGstData("2A");
  //   }


  //   // If workspace is available and data is not already present for 2B, fetch 2B data
  //   if (workspace && !gst2BData) {
  //     fetchGstData("2B");
  //   }

  // }, [workspace, gst2AData, gst2BData, setGst2AData, setGst2BData]);

  const [isMonthsDropdownOpen, setIsMonthsDropdownOpen] = useState(false);
  // const [selectedMonths, setSelectedMonths] = useState([
  //   "All", // Initialize with "All" selected
  //   "042024",
  //   "052024",
  //   "062024",
  //   "072024",
  //   "082024",
  //   "092024",
  //   "102024",
  //   "112024",
  //   "122024",
  // ]);

  // const [monthOptions] = useState([
  //   { value: "All", label: "All Months" },
  //   { value: "042024", label: "Apr-2024" },
  //   { value: "052024", label: "May-2024" },
  //   { value: "062024", label: "Jun-2024" },
  //   { value: "072024", label: "Jul-2024" },
  //   { value: "082024", label: "Aug-2024" },
  //   { value: "092024", label: "Sep-2024" },
  //   { value: "102024", label: "Oct-2024" },
  //   { value: "112024", label: "Nov-2024" },
  //   { value: "122024", label: "Dec-2024" },
  // ]);


  const [selectedMonths, setSelectedMonths] = useState(["All"]);
  const [monthOptions, setMonthOptions] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (month) => {
    setSelectedMonths((prev) => {
      if (month === "All") {
        // Toggle "All" checkbox
        if (prev.includes("All")) {
          return []; // Deselect all months if "All" is unchecked
        } else {
          return ["All", ...monthOptions.map((option) => option.value)];
        }
      } else {
        if (prev.includes(month)) {
          return prev.filter((item) => item !== month); // Deselect specific month
        } else {
          return [...prev, month]; // Select specific month
        }
      }
    });
  };

  // Prepare selected months for API payload
  const getPayloadMonths = () => {
    // If "All" is selected, use all months, otherwise return selected months excluding "All"
    return selectedMonths.includes("All")
      ? monthOptions.map((option) => option.value).filter((value) => value !== "All")
      : selectedMonths;
  };

  // Fetch GST data API
  const fetchGstData = async (tableType) => {
    const apiUrl = "https://fuzzyapi.finkraftai.com/sum-gst-eligible";
    setLoading(true);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspaceName: workspace,
          tableType: tableType, // Send table type based on toggle (2A or 2B)
          month: getPayloadMonths(), // Send selected months (processed by getPayloadMonths)
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (tableType === "2A") {
          setGst2AData(data); // Store data for 2A
        } else {
          setGst2BData(data); // Store data for 2B
        }
      } else {
        console.error("Failed to fetch GST data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    // Call fetch for both table types (2A and 2B)
    fetchGstData("2A");
    fetchGstData("2B");
    setIsMonthsDropdownOpen(false);
  };

  useEffect(() => {
    if (workspace) {
      handleSubmit(); // Initial fetch
    }
  }, [workspace]);

  const currentData = is2A ? gst2AData : gst2BData;

  const [userDetails, setUserDetails] = useRecoilState(userInfo || { workspaces: [] });



  const [isFetchingData, setIsFetchingData] = useState(true);

  useEffect(() => {
    if (!workspace) return;

    const fetchCounts = async () => {
      try {
        setIsFetchingData(true); // Show loader
        const response = await fetch(
          `https://fuzzyapi.finkraftai.com/get-eligibility-count?workspace=${workspace}&tableType=${is2A ? "2A" : "2B"}`
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log("API Data:", data);

        setCounts({
          exempted: data.counts?.exempted || 0,
          eligible: data.counts?.eligible || 0,
          notEligible: data.counts?.not_eligible || 0,
        });

        setPercentages({
          exempted: data.percentages?.exempted || 0,
          eligible: data.percentages?.eligible || 0,
          notEligible: data.percentages?.not_eligible || 0,
        });
      } catch (err) {
        console.error("Error fetching counts:", err);
        setCounts({});
        setPercentages({});
      }
      finally {
        setIsFetchingData(false);
      }
    };

    fetchCounts();
  }, [workspace, is2A]);


  const [isAccessDeniedModalVisible, setAccessDeniedModalVisible] = useState(false);



  useEffect(() => {
    // Fetch workspace data on mount
    const fetchUserInfo = async () => {
      try {
        const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
        console.log("API Response:", response);
        if (response.status && response.data?.workspaces) {
          setWorkspaces(response.data.workspaces);
          setUserDetails({ ...userDetails, workspaces: response.data.workspaces });
          setWorkspace(response.data?.workspaces?.[0]?.name);
          setSelectedWorkspace(response.data?.workspaces?.[0]?.name);
          setFilteredOptions(response.data.workspaces); // Initialize filtered options
          const userWorkspace = response.data.workspaces[0]; // Assuming first workspace
          setUserRole(userWorkspace?.role);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  // Filter options based on the search query
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOptions(
      workspaces.filter((workspace) =>
        workspace.name.toLowerCase().includes(query)
      )
    );
  };

  // Handle selection of a workspace
  const handleSelectOption = (workspace) => {
    setSelectedWorkspace(workspace.name);
    setWorkspace(workspace.name);
    setIsDropdownOpen(false);
    setSearchQuery("");
    setFilteredOptions(workspaces); // Reset the filtered options
  };


  const toggleTable = () => {
    setIs2A((prev) => !prev); // Toggle between 2A and 2B
  };


  const [showPopup, setShowPopup] = useState(false);


  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };

  const handleCancel = () => {
    setShowPopup(false);
  };
  const [rowData, setRowData] = useState([]);


  const transformData = (apiData) => {
    // Extract InvoiceBreakdown safely
    const breakdown = apiData[0]?.InvoiceBreakdown || {};

    // Safely extract Invoice Received and Invoice Not Received breakdowns
    const receivedBreakdown = breakdown["Invoice Received"]?.EligiblityBreakdown || {};
    const notReceivedBreakdown = breakdown["Invoice Not Received"]?.EligiblityBreakdown || {};

    // Define a function to safely access nested properties
    const getSafeValue = (obj, key, defaultValue = 0) => obj?.[key] || defaultValue;

    return [
      {
        category: "Eligible",
        receivedInvoiceCount: getSafeValue(receivedBreakdown.Eligible, "InvoiceCount"),
        receivedTotalAmount: getSafeValue(receivedBreakdown.Eligible, "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown.Eligible, "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(notReceivedBreakdown.Eligible, "InvoiceCount"),
        notReceivedTotalAmount: getSafeValue(notReceivedBreakdown.Eligible, "Amount"),
        notReceivedTotalGST: getSafeValue(notReceivedBreakdown.Eligible, "GSTAmount"),
      },
      {
        category: "Not Eligible",
        receivedInvoiceCount: getSafeValue(receivedBreakdown["Not Eligible"], "InvoiceCount"),
        receivedTotalAmount: getSafeValue(receivedBreakdown["Not Eligible"], "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown["Not Eligible"], "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(notReceivedBreakdown["Not Eligible"], "InvoiceCount"),
        notReceivedTotalAmount: getSafeValue(notReceivedBreakdown["Not Eligible"], "Amount"),
        notReceivedTotalGST: getSafeValue(notReceivedBreakdown["Not Eligible"], "GSTAmount"),
      },
      {
        category: "Exempted",
        receivedInvoiceCount: getSafeValue(receivedBreakdown.Exempted, "InvoiceCount"),
        receivedTotalAmount: getSafeValue(receivedBreakdown.Exempted, "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown.Exempted, "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(notReceivedBreakdown.Exempted, "InvoiceCount"),
        notReceivedTotalAmount: getSafeValue(notReceivedBreakdown.Exempted, "Amount"),
        notReceivedTotalGST: getSafeValue(notReceivedBreakdown.Exempted, "GSTAmount"),
      },
    ];
  };




  const columnDefs = [
    {
      headerName: "Category",
      field: "category",
    },
    {
      headerName: "Invoice Received",
      children: [
        {
          headerName: "Invoice Count",
          field: "receivedInvoiceCount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total Amount",
          field: "receivedTotalAmount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total GST Amount",
          field: "receivedTotalGST",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
      ],
    },
    {
      headerName: "Invoice Not Received",
      children: [
        {
          headerName: "Invoice Count",
          field: "notReceivedInvoiceCount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total Amount",
          field: "notReceivedTotalAmount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total GST Amount",
          field: "notReceivedTotalGST",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value);
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
      ],
    },
  ];


  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1
  };

  useEffect(() => {
    const options = getMonthOptions();
    setMonthOptions(options);
    // Set selected months to include all available months (including "All")
    const allMonthsSelected = options.map(option => option.value);
    setSelectedMonths(allMonthsSelected); // Select all by default
  }, []);






  useEffect(() => {
    const currentWorkspace = localStorage.getItem("currentWorkspace");
    const dataType = is2A ? "2A" : "2B"; // Determine the data type based on toggle
    setIsFetchingData(true);

    // API call
    fetch("https://fuzzyapi.finkraftai.com/einvoice-pipeline", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        workspace_id: currentWorkspace,
        type: dataType, // Include the type (2A or 2B) in the payload
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const transformedData = transformData(data);
        setRowData(transformedData);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsFetchingData(false); // Ensure fetching state is reset
      });
  }, [is2A]); // Re-run effect when is2A changes

  const [isPivotTableModalOpen, setIsPivotTableModalOpen] = useState(false);

  // Handle modal toggle
  const handlePivotTableModalToggle = () => {
    setIsPivotTableModalOpen(!isPivotTableModalOpen);
  };
  const [userRole, setUserRole] = useRecoilState(roleInfo);

  const [activeTab, setActiveTab] = useState("invoice");
  const [showVendorDetails, setShowVendorDetails] = useRecoilState(vendorDetailsDisplay);

  // const handleValidate = () => {
  //   setShowVendorDetails(true);
  // };

  const [formData, setFormData] = useState({
    vendorGSTIN: "",
    financialYear: "",
    invoiceType: "",
    invoiceNumber: "",
    irnNumber: ""
  });

  const [isIrnMode, setIsIrnMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [irnloading, setIrnLoading] = useState(false);

  const [invoiceStatus, setInvoiceStatus] = useState("Recieved");

  // const [invoiceData, setInvoiceData] = useState({
  //   regtime: "Not Available",
  //   irn: "Not Available",
  //   regirp: "Not Available",
  //   status: "Not Available"
  // });
  console.log(is2A, "Checking the toggle")
  const currentWorkspace = localStorage.getItem('currentWorkspace');
  console.log(currentWorkspace, "currentWorkspace")


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useRecoilState(irndata);

  const mapInvoiceType = (type) => {
    switch (type) {
      case "INV":
        return "E-tax invoice";
      case "CRN":
        return "Credit note";
      case "DBN":
        return "Debit note";
      default:
        return type; // Return the original type if no mapping is found
    }
  };
  const mapInvoiceStatus = (status) => {
    return status === "Invoice Received" ? "Received" : "Not Received";
  };

  const handleValidate = async () => {

    if (
      (activeTab === "invoice" && (!formData.vendorGSTIN || !formData.financialYear || !formData.invoiceType || !formData.invoiceNumber)) ||
      (activeTab === "irn" && !formData.irnNumber)
    ) {
      alert("Please fill all the required fields.");
      return;
    }

    if (activeTab === "irn" && (!formData.irnNumber || formData.irnNumber.length !== 64)) {
      alert("IRN Number must be exactly 64 characters.");
      return;
    }



    setIrnLoading(true);
    try {
      const currentWorkspace = localStorage.getItem('currentWorkspace');
      // const payload = activeTab === "irn" ? { irnNumber: formData.irnNumber } : formData;
      const collection = is2A ? "all_vendor_tally_data_spark" : "all_vendor_tally_twob";
      const payload = activeTab === "irn"
        ? { irnNumber: formData.irnNumber, collection: collection, db: "gstservice", currentWorkspace }
        : { ...formData, collection: collection, db: "gstservice", currentWorkspace };
      const response = await fetch("https://stage-apiplatform.finkraftai.com/api/finalytics/tables/data/vendorValidate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      console.log(response, "response")

      const data = await response.json();
      // const data=response.data
      // setIsModalVisible(true)
      // setShowVendorDetails(true);
      // setInvoiceStatus(data.web_data?.irn ? "✔ Received" : "Not Received");
      // setInvoiceData({
      //   regtime: data.web_data?.regtime || "Not Available",
      //   irn: data.web_data?.irn || "Not Available",
      //   regirp: data.web_data?.regirp || "Not Available",
      //   status: data.web_data?.status || "Not Available",
      // });
      console.log(data.data, "data")
      setInvoiceData({
        inum: data.data.inum,
        date: data.data.dt,
        eligibleStatus: data.data.einvoiceEligibility,
        invoiceStatus: mapInvoiceStatus(data.data.invoiceStatus),
        vendorName: data.data.enablement.legalNm,
        pan: data.data.pan,
        vendorGSTIN: data.data.ctin,
        totalGSTAmount: data.data.totalGstAmount,
        // invoiceType:data.data.invType
        invoiceType: mapInvoiceType(data.data.invType),
        assetLink: data.data.assetlink


      })
      console.log(setInvoiceData.vendorData, "gstin value check")
      const gstinPrefix = data.data.ctin?.substring(0, 2);
      console.log(gstinPrefix, "gstinPrefix")
      const stateShortForm = stateShortFormMapping[gstinPrefix] || "Unknown";
      console.log(stateShortForm, "state form")
      setStateShortForm(stateShortForm)

      if (data) {
        setIsModalVisible(true);
        setShowVendorDetails(true);
      }
    } catch (error) {
      console.error("Error validating data:", error);
      alert("Validation failed. Please try again.");
    } finally {
      setIrnLoading(false);
    }
  };

  console.log(invoiceData, "invoiceData")


  const handleInputChange = (e) => {
    setGstin(e.target.value);
  };

  const handleSearchgstin = () => {
    if (gstin.trim()) {
      setIsSideMenuOpen(true);
    }
  };

  const closeSideMenu = () => {
    setIsSideMenuOpen(false);
  };

  const [gstin, setGstin] = useState("");
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);



  return hasGstin ? (
    <div className="dashboard">
      <Modal
        title="Access Denied"
        open={isAccessDeniedModalVisible}
        onOk={() => setAccessDeniedModalVisible(false)}
        onCancel={() => setAccessDeniedModalVisible(false)}
        centered
      >
        <p>You need admin access to view this page.</p>
      </Modal>
      {/* <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        centered
        closeIcon={
          <span
            style={{
              color: "black",
              fontSize: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              marginTop: '5px'
            }}
            onClick={() => setFormData({ vendorGSTIN: "", financialYear: "", invoiceType: "", invoiceNumber: "" })}
          >
            ×
          </span>
        }

        footer={null} // Removes OK and Cancel buttons
      >
        <p style={{ color: "red", fontSize: "15px" }}>
          IRN is not registered on Invoice Registration Portal (IRP).
        </p>
      </Modal> */}

      {/* Pivot Table Modal */}
      {isPivotTableModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content-pivot">
            <span className="close" style={{ marginBottom: '20px' }} onClick={handlePivotTableModalToggle}>
              &times;
            </span>
            {/* Display Ag-Grid table inside modal */}
            <div
              className="ag-theme-alpine"
              style={{ height: 210, width: '100%', marginTop: '50px' }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
              />
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            padding: '40px',
          }}
        >
          <h3 style={{ marginBottom: '40px', fontWeight: '500', color: 'grey' }}>You will be Re-Directed to Onboarding.Finkratf our support portal Login with your organization credentials to raise a ticket" and the button will be "cancel" , "Raise Ticket"</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              style={{
                padding: '10px 60px',
                borderRadius: '5px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
                fontSize: '18px'
              }}
              onClick={() => window.open('https://onboarding.finkraft.ai/projects/450451/plan', '_blank')}
            >
              Redirect ↗️
            </button>
            <button
              onClick={closeModal}
              style={{
                padding: '10px 60px',
                borderRadius: '5px',
                backgroundColor: '#ccc',
                color: '#000',
                border: 'none',
                cursor: 'pointer',
                fontSize: '18px'
              }}
            >
              Cancel 🚫
            </button>
          </div>
        </div>
      )}
      <div className="dashboard-header">
        <img src={FinkraftLogo} alt="logo" className="finklogo" />
        <div className="dashboard-right-header">
          <div >
            <label style={{
              position: 'relative',
              display: 'inline-block',
              width: '220px', /* Adjust width for both names */
              height: '32px',
              backgroundColor: '#ffffff', /* White background */
              borderRadius: '10px',
              border: '2px solid #0A8394', /* Light green border */
              cursor: 'pointer',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', /* Subtle shadow */
              overflow: 'hidden',
              display: 'flex', /* Ensures no gap between elements */
            }}>
              <input type="checkbox" checked={is2A} onChange={toggleTable} style={{ display: 'none' }} />
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
                height: '100%',
                width: '100%',
                fontFamily: 'Arial, sans-serif',
                fontSize: '13px',
                fontWeight: '600',
              }}>
                <span style={{
                  flex: '1',
                  textAlign: 'center',
                  color: is2A ? '#ffffff' : '#000', /* Default text color */
                  backgroundColor: is2A ? '#0A8394' : 'transparent', /* Light green background */
                  borderRadius: '50px',
                  padding: '6px 20px', /* Reduced padding to eliminate gap */
                  transition: 'all 0.3s ease',
                }}>
                  2A
                </span>
                <span style={{
                  flex: '1',
                  textAlign: 'center',
                  color: !is2A ? '#ffffff' : '#000', /* Default text color */
                  backgroundColor: !is2A ? '#0A8394' : 'transparent', /* Light green background */
                  borderRadius: '50px',
                  padding: '6px 20px', /* Reduced padding to eliminate gap */
                  transition: 'all 0.3s ease',
                }}>
                  2B
                </span>
              </div>
            </label>

          </div>
          {/* <div
            className="vendorfollowup-dropdown-container"
            style={{ marginTop: "25px" }}
          >
            <div
              className={`vendorfollowup-dropdown-header ${
                isDropdownOpen ? "active" : ""
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div> */}
          <Button
            className='header-btn-top'
            onClick={openModal}
          >
            <img src={phoneIcon} alt='' style={{ width: '19px', padding: '0px' }} />
            Contact Support
          </Button>
          <Button
            className='header-btn-top'
            onClick={opensetting}
          >
            <FontAwesomeIcon icon={faGear} />
            Settings
          </Button>
          <Button
            className='header-btn-top'
            onClick={() => setShowPopup(true)}
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
            Log Out
          </Button>
        </div>
      </div>
      <div className="container-first-row">
        <div className="header">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            {/* <div className="icon">G</div> */}
            <img
              src={GstinLogo}
              alt="logo"
              style={{ width: "35px", marginTop: "5px" }}
            />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for
            the claimable amount. To proceed, we require you to authenticate
            with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button" onClick={handleGstIntegration}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M4 12h16M12 4l8 8-8 8" />
              </svg>
              Invite user who has GST credentials
            </button>

            <button className="add-pan-button" onClick={handleAddPan}>
              Add PAN / GSTIN
            </button>
          </div>

          <div className="first-row-info">
            ⓘ Invite user who has credentials
          </div>
        </div>

        {/* <div className="entities-section">
          <div className="entities-header">
            <h3>
              {entities.length} entities added, Are you associated with more
              entities?
            </h3>
            <button className="view-detail" onClick={summarypage}>
              View in detail ↗{" "}
            </button>
          </div>

          <div className="entities-list">
            {entities.map((entity) => (
              <div key={entity.pan} className="entity-card">
                <div className="entity-header">
                  <img src={tickIcon} alt="tick" />
                  <span className="pan-label">PAN :</span>
                  <span className="pan-value">{entity.pan}</span>
                  <span className="status-active">● Active</span>
                </div>

                <div className="underline-1"></div>

                <div className="entity-details">
                  <div className="detail-group">
                    <p className="label">Trade name :</p>
                    <p className="value">{entity.tradeName}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">Your Associated GSTIN's :</p>
                    <p className="value">{entity.gstin}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">Cred Status :</p>
                    <span
                      className={`status-badge ${entity.credStatus.toLowerCase()}`}
                    >
                      {entity.credStatus}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div> */}
        <div className="einvoicecard-card-container" >



          <div className="einvoice-toggle-switch">
            <div
              className={`einvoice-toggle-option ${activeTab === "invoice" ? "active" : ""}`}
              onClick={() => setActiveTab("invoice")}
            >
              Invoice #
            </div>
            <div
              className={`einvoice-toggle-option ${activeTab === "irn" ? "active" : ""}`}
              onClick={() => setActiveTab("irn")}
            >
              IRN #
            </div>
            <div className={`einvoice-toggle-indicator ${activeTab === "irn" ? "right" : ""}`} />
          </div>

          {!showVendorDetails && (
            <>
              <h3 className="einvoicecard-title">
                {activeTab === "invoice" ? "E-invoice Validate" : "IRN Validate"}
              </h3>

              {activeTab === "invoice" ? (
                <>
                  <div className="einvoicecard-form-group" style={{ position: "relative" }}>
                    {irnloading && (
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                      }}>
                        <BeatLoader color="#36D7B7" size={20} />
                      </div>
                    )}

                    <div className="einvoicecard-input-container">
                      <label>Vendor GSTIN<span>*</span></label>
                      <input type="text" name="vendorGSTIN" value={formData.vendorGSTIN} onChange={handleChange} placeholder="Enter Vendor GSTIN here" />
                    </div>
                    <div className="einvoicecard-input-container">
                      <label>Financial Year<span>*</span></label>
                      <input type="text" name="financialYear" value={formData.financialYear} onChange={handleChange} placeholder="Enter Financial Year" />
                    </div>
                  </div>
                  <div className="einvoicecard-form-group">
                    <div className="einvoicecard-input-container">
                      <label>Invoice Type<span>*</span></label>
                      <select name="invoiceType" className="invoice-select" value={formData.invoiceType} onChange={handleChange}>
                        <option value="" disabled>Choose invoice type</option>
                        <option value="tax-invoice">Tax Invoice</option>
                        <option value="debit-note">Debit Note</option>
                        <option value="credit-note">Credit Note</option>
                      </select>
                    </div>
                    <div className="einvoicecard-input-container">
                      <label>Invoice Number<span>*</span></label>
                      <input type="text" name="invoiceNumber" value={formData.invoiceNumber} onChange={handleChange} placeholder="Enter Invoice Number" />
                    </div>
                  </div>
                  <div className="einvoicecard-button-group">
                    <button className="einvoicecard-reset" onClick={() => setFormData({ vendorGSTIN: "", financialYear: "", invoiceType: "", invoiceNumber: "" })}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M16.6673 9.16658C16.4635 7.7001 15.7832 6.3413 14.7312 5.2995C13.6792 4.25769 12.3138 3.59068 10.8454 3.40121C9.37697 3.21174 7.887 3.51032 6.60499 4.25096C5.32297 4.99159 4.32003 6.1332 3.75065 7.49992M3.33398 4.16658V7.49992H6.66732M3.33398 10.8333C3.53778 12.2998 4.21809 13.6586 5.27012 14.7004C6.32214 15.7422 7.68752 16.4092 9.15593 16.5987C10.6243 16.7881 12.1143 16.4896 13.3963 15.7489C14.6783 15.0083 15.6813 13.8667 16.2507 12.5M16.6673 15.8333V12.5H13.334" stroke="#0A8394" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>Reset</button>
                    <button className="einvoicecard-validate" onClick={handleValidate} >Validate Now</button>
                  </div>
                </>
              ) : (
                <>
                  <div className="einvoicecard-form-group">
                    <div className="einvoicecard-input-container">
                      <label>IRN Number<span>*</span></label>
                      <input type="text" name="irnNumber" value={formData.irnNumber} onChange={handleChange} style={{ width: '200%' }} placeholder="Enter IRN Number" />
                    </div>
                  </div>
                  <div className="einvoicecard-button-group">
                    <button className="einvoicecard-cancel">Cancel</button>
                    <button className="einvoicecard-validate" onClick={handleValidate}>Validate Now</button>
                  </div>
                </>
              )}
            </>
          )}

          {showVendorDetails && (
            <div className="vendor-details">
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="vendorName">{invoiceData.vendorName}</p>
                {/* <p className="vendorName">Vendor_name</p> */}
                <div className="detailsWrapper">
                  <div className="panDetails">
                    <div className="panTitle"> PAN :</div>
                    <div className="panValue"> {invoiceData.pan}</div>
                  </div>
                  <div className="gstinDetails">
                    <div className="gstinTitle">GSTIN : </div>
                    <div className="gstinValue">
                      <div className="stateForm"> {stateShortForm}</div>
                      <div className="gstin">  {invoiceData.vendorGSTIN}</div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="vendor-table">


                {/* <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
  <span>Irn</span>
  <span style={{
    paddingLeft: '10px',
    wordBreak: 'break-all',
    maxWidth: '50%',
  }}>
    {invoiceData.irn}
  </span>
</div> */}


                <div className="row">
                  <span>E-invoice number</span>
                  <span  >{invoiceData.inum}<Tag className="invoiceTypeRow" style={{ color: "#0A8394", borderColor: "#03A5B733", textAlign: "center",width:"100px" }}>{invoiceData.invoiceType}</Tag></span>
                </div>
                <div className="row">
                  <span>Date</span>
                  <span >{invoiceData.date}</span>
                </div>
                <div className="row">
                  <span>Eligible status</span>
                  {/* <span ><Tag className="eligibleStatusRow">{invoiceData.eligibleStatus}</Tag></span> */}
                  <Tag className="eligibleStatusRow">
                    <CircleIcon 
                    className="statusIcon"
                    style={{ color: invoiceData.eligibleStatus === 'Eligible' ? 'green' : 'red' ,
                      width: '9px', 
                      height: '9px'
                      }} />
                    {invoiceData.eligibleStatus}
                  </Tag>

                </div>
                <div className="row">
                  <span>Amount</span>
                  <span >INR {invoiceData.totalGSTAmount}</span>
                </div>
                <div className="row">
  <span>E-invoice status</span>
  <span>
    <Tag className={`invoiceStatus ${invoiceData.invoiceStatus === 'Received' ? 'received' : 'notReceived'}`}>
      {/* Conditional SVG Icon */}
      {invoiceData.invoiceStatus === 'Received' ? (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.75 7L6.91667 8.16667L9.25 5.83333M2.25 7C2.25 7.68944 2.3858 8.37213 2.64963 9.00909C2.91347 9.64605 3.30018 10.2248 3.78769 10.7123C4.2752 11.1998 4.85395 11.5865 5.49091 11.8504C6.12787 12.1142 6.81056 12.25 7.5 12.25C8.18944 12.25 8.87213 12.1142 9.50909 11.8504C10.146 11.5865 10.7248 11.1998 11.2123 10.7123C11.6998 10.2248 12.0865 9.64605 12.3504 9.00909C12.6142 8.37213 12.75 7.68944 12.75 7C12.75 6.31056 12.6142 5.62787 12.3504 4.99091C12.0865 4.35395 11.6998 3.7752 11.2123 3.28769C10.7248 2.80018 10.146 2.41347 9.50909 2.14963C8.87213 1.8858 8.18944 1.75 7.5 1.75C6.81056 1.75 6.12787 1.8858 5.49091 2.14963C4.85395 2.41347 4.2752 2.80018 3.78769 3.28769C3.30018 3.7752 2.91347 4.35395 2.64963 4.99091C2.3858 5.62787 2.25 6.31056 2.25 7Z" stroke="#1E7734" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      ) : (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 3.5L4 10.5M4 3.5L11 10.5" stroke="#C8170D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
      )}
      {invoiceData.invoiceStatus}
    </Tag>
  </span>
</div>

              </div>
              <div className="vendor-actions">

                <button
                  style={{ width: '180px' }}
                  className="einvoicecard-reset" onClick={() => {
                    setShowVendorDetails(false);
                    setFormData({
                      vendorGSTIN: "",
                      financialYear: "",
                      invoiceType: "",
                      invoiceNumber: "",
                    });
                  }}> <ArrowIcon style={{ width: '24px', height: '24px' }} />
                    Back</button>
                {/* {invoiceData.invoiceStatus === "Received" ? (
                  <button style={{ backgroundColor: "#0A8394", color: 'white', width: '180px' }} className="einvoicecard-view" onClick={() => window.open(invoiceData.assetLink, "_blank")}><EyeOutlined style={{ marginRight: '5px' }} />View</button>
                ) : invoiceData.invoiceStatus === "Not Received" ? (
                  <Button className="einvoicecard-request" onClick={handleRequestClick} style={{ backgroundColor: "#0A8394", color: 'white', width: '180px' }}>Request</Button>
                ) : null} */}
{
(invoiceData.invoiceStatus === "Received" && invoiceData.eligibleStatus === "Eligible" && invoiceData.assetLink) || invoiceData.assetLink ? (
    <button 
      style={{ backgroundColor: "#0A8394", color: 'white', width: '180px' }} 
      className="einvoicecard-view" 
      onClick={() => window.open(invoiceData.assetLink, "_blank")}
    >
      <EyeOutlined style={{ marginRight: '5px' }} />View
    </button>
  ) : invoiceData.invoiceStatus === "Not Received" && invoiceData.eligibleStatus === "Eligible" ? (
    <Button 
      className="einvoicecard-request" 
      onClick={handleRequestClick} 
      style={{ backgroundColor: "#0A8394", color: 'white', width: '180px' }}
    >
      Request
    </Button>
  ) : null
}

                {isEmailModalOpen && <EmailModal vendorData={[{ gstin: invoiceData.vendorGSTIN, vendorName: invoiceData.vendorName }]} isOpen={isEmailModalOpen}
                  onClose={closeEmailModal} />}
              </div>
            </div>
          )}
        </div>

      </div>

      <div className="container-second-row">


        <>

          <section className="dashboard-section" >
            <div className="section-header">
              <div className="header-tabs">
                <div>
                  <button className="tab-button active">Immediate action</button>
                </div>

                <div>
                  <div className="months-dropdown-container">
                    <button
                      className="months-dropdown-button"
                      onClick={() => setIsMonthsDropdownOpen(!isMonthsDropdownOpen)}
                    >
                      {selectedMonths.includes("All")
                        ? "All Months Selected"
                        : `${selectedMonths.length} Month(s) Selected`}
                      <span className="dropdown-toggle">{isMonthsDropdownOpen ? "▼" : "▲"}</span>
                    </button>

                    {isMonthsDropdownOpen && (
                      <div className="months-dropdown-menu">
                        {monthOptions.map((month) => (
                          <div key={month.value} className="months-checkbox-option">
                            <input
                              type="checkbox"
                              id={month.value}
                              checked={selectedMonths.includes(month.value)}
                              onChange={() => handleCheckboxChange(month.value)}
                            />
                            <label htmlFor={month.value}>{month.label}</label>
                          </div>
                        ))}
                        <button onClick={handleSubmit} className="months-submit-button">
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>



            <div className="section-subheading">
              <div>Follow-ups </div>
              <a href="#" className="view-detail" onClick={handleNavigation}>
                View in detail ↗
              </a>
            </div>


            <div className="Amount-card">
              <div className="Amount-card-left">
                <img src={warninging} alt="Warning" />
                <p>
                  Eligible but No E-Invoice generated :<br />
                  (Total GST amount at risk)
                </p>
              </div>
              <div className="Amount-card-right">
                {loading ? (
                  <div className="loading-container">
                    <div className="spinner"></div> {/* Animated spinner */}
                  </div>
                ) : currentData ? (
                  <h1>₹ {currentData.totalGstAmountSum.toLocaleString("en-IN")}</h1>
                ) : (
                  <h1>₹ No GST data available</h1>
                )}
              </div>
            </div>
            <div className="Amount-card" style={{ marginTop: '20px' }}>
              <div className="Amount-card-left">
                <img src={pivot} style={{ width: '30px', marginRight: '20px' }} />
                <h1>Summary </h1>
              </div>
              <div className="Amount-card-right" onClick={handlePivotTableModalToggle} style={{ cursor: 'pointer' }}>
                View Pivot Table
              </div>
            </div>


            {/* <div
      className="ag-theme-alpine"
      style={{ height: 210, width: "100%",marginTop:'20px' }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </div> */}
          </section>
        </>




        {/* Overview Section */}
        <section className="dashboard-section" onClick={handleNavigation1}>
          <div className="section-header">
            <div className="header-tabs">
              <button className="tab-button active">Overview</button>
            </div>
          </div>

          <div className="section-subheading">
            <div> Summary </div>
            <a href="#" className="view-detail">
              View in detail ↗
            </a>
          </div>

          <div className="section-content">
            <div className="eligibility-chart">
              <div className="chart-heading">Vendor E-invoice eligibility</div>
              <div className="chart-container">
                {isFetchingData && (
                  <div className="loader-overlay">
                    <div className="spinner"></div> {/* Animated spinner */}
                  </div>
                )}
                <div ref={chartRef} style={{ width: "70%", height: "255px" }} />
                <div className="custom-legend">
                  <ul>
                    <li>
                      <span
                        className="legend-marker"
                        style={{ backgroundColor: "#006D77" }}
                      ></span>
                      Eligible: {counts.eligible}
                    </li>
                    <li>
                      <span
                        className="legend-marker"
                        style={{ backgroundColor: "#83C5BE" }}
                      ></span>
                      Not-eligible: {counts.notEligible}
                    </li>
                    <li>
                      <span
                        className="legend-marker"
                        style={{ backgroundColor: "#EDF6F9" }}
                      ></span>
                      Exempted: {counts.exempted}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="vendor-compliance-container">
          <div className="card">
            <button className="vendor-score">Vendor score</button>
            <h2>Check your vendor compliance</h2>
            <label>Vendor GSTIN<span className="required">*</span></label>
            <input type="text" placeholder="Enter GSTIN" value={gstin} onChange={handleInputChange} />
            <Button className="check-btn" onClick={handleSearchgstin}>Check now →</Button>
          </div>
        </div>
        {isSideMenuOpen && (
          <div className="side-menu">
            <button className="close-btn" onClick={closeSideMenu}><svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path d="M15.5 5L5.5 15M5.5 5L15.5 15" stroke="#0A8394" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg></button>
            <MainContentVendor gstin={gstin} workspace={selectedWorkspace} />
          </div>
        )}
        {showPopup && (
          <div
            // style={{
            //   position: "fixed",
            //   top: 0,
            //   left: 0,
            //   width: "100vw",
            //   height: "100vh",
            //   backgroundColor: "rgba(0, 0, 0, 0.5)",
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '40px',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: '400px',
              height: '10%'
            }}
          >
            <div
              style={{
                borderRadius: "10px",
                padding: "20px",
                textAlign: "center",
                maxWidth: "400px",
                width: "100%",
              }}
            >
              <h1 style={{ margin: "0 0 20px 0", fontWeight: '600' }}>Logout Confirmation</h1>
              <p style={{ margin: "0 0 20px 0" }}>
                Are you sure you want to log out?
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  onClick={handleCancel}
                  style={{
                    backgroundColor: "#ccc",
                    color: "#333",
                    border: "none",
                    padding: "10px 35px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  No
                </button>
                <button
                  onClick={handleLogout}
                  style={{
                    backgroundColor: "#ff4d4f",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  ) : (
    <OnboardingView />
  );
};



export default React.memo(GSTIntegration);